.selectFlexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.diagnosisSelect {
  width: 250px;
}
.button {
  margin-left: 20px;
}
.spinnerContainer {
  margin-left: 45%;
  margin-top: 20%;
}
.centerSpinnerContainer {
  display: flex;
  justify-content: center;
}
