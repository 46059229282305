.appBarTitle {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.centerTabs {
  max-width: 100%;
  display: flex;
  justify-content: center;
}
.logo {
  padding-left: 20px;
}
.logo-image {
  margin-right: 20px;
}
.pageContainer {
  margin-top: 10em;
}
.flex {
  display: flex;
}
.badgeSidePosition {
  margin-left: 10px;
  margin-top: 13px;
}
.divider {
  font-size: 2em;
}
.menuContainer {
  margin-top: 45px;
}
.usernameText {
  text-align: center;
}
