.buttonContainer {
  margin-right: 15px;
}
.tableContainer {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}
.yellowBackground {
  background-color: yellow;
}
.headerCell {
  background-color: #90caf9;
}
.tableCell {
  border: 1px solid #ddd;
  padding: 5px;
  word-wrap: break-word;
  max-width: 100px;
}
