#remoteMonitorReport {
  text-align: center;
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: -webkit-fill-available;
  table-layout: fixed;
  padding: 20px;
}

#remoteMonitorReport table {
  width: 100%;
  width: 100%;
  width: -moz-available;
}

#remoteMonitorReport td:nth-of-type(1),
#remoteMonitorReport th:nth-of-type(1) {
  width: 10%;
}

#remoteMonitorReport th:nth-of-type(2) {
  width: 20%;
}

#remoteMonitorReport th:nth-of-type(3) {
  width: 20%;
}

#remoteMonitorReport td,
#remoteMonitorReport th {
  border: 1px solid #ddd;
  padding: 8px;
}

@media print {
  #remoteMonitorReport {
    text-align: center;
    font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: -webkit-fill-available;
    table-layout: fixed;
    padding: 20px;
  }
}

.centerContent {
  font-size: 20;
  display: grid;
  place-items: center;
  height: calc(100vh - 100px);
}

.red {
  color: red;
}

.bigText {
  font-size: 20px;
}

.tableHeaderPageTitle {
  font-size: 30px;
  background: #90caf9;
  color: #000000;
  position: sticky;
  top: 0px;
  text-align: center;
}

.printButton {
  float: right;
  margin-right: 10px;
}

.reportSelectionHeaderContainer {
  width: 70%;
  margin: 10px auto;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  gap: 20;
}

.rmReportColumnHeader {
  padding: 5px;
  background: #90caf9;
  position: sticky;
  top: 50px;
}

.loaderStyle {
  display: block;
  margin: 0 auto;
}

.rmReportRowBackground {
  background-color: #dddcd7;
}

.prvNameListItem {
  text-align: left;
  list-style-type: none;
  margin-left: 70px;
}

.deviceDetailsText {
  text-align: left;
  list-style-type: none;
}

.blueText {
  color: #2684ff;
}

.redText {
  color: red;
}
