.exceptionsLoading {
  display: grid;
  place-items: center;
  height: calc(100vh - 300px);
}

.pageTitle {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}
