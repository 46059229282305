.recordHeader {
  display: flex;
  justify-content: space-between;
  background-color: #ccc;
}

.recordHeader span {
  flex-direction: row;
  margin: 0 10px 10px 0px;
}
.recordData {
  margin: 0 10px;
}
.textCenter {
  text-align: center;
}
.printButtonMargin {
  margin-top: 10px;
}

.callRecordsCenterLoading {
  height: calc(100vh - 200px);
  display: grid;
  place-items: center;
}
