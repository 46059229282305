.confirmationContent {
  margin-left: 24px;
}

.archivedContent {
  margin-top: 160px;
}

.archivedListCenterLoading {
  display: grid;
  place-items: center;
  height: calc(100vh - 200px);
}
