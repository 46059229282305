.reset-password {
  margin-left: 30px;
}

.login-page {
  display: flex;
  justify-content: center;
  margin-top: 12%;
}
.button {
  margin-top: 20px;
}
.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
  margin-left: 20px;
}
.caregem-logo {
  width: 180px;
  margin: 5px;
}
.font20px {
  font-size: 20px;
}
.red {
  color: red;
}
