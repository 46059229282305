.survey_report{
   margin-top: 20px;
}

#patientSympData{
    margin-top: 50px;
}


.table-data > td {
    height: 50px;
}