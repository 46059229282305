#rpmEligibleData td {
  overflow: hidden;
} /*Hide text outside the cell.*/
#rpmEligibleData td:nth-of-type(1),
#rpmEligibleData th:nth-of-type(1) {
  width: 12%;
} /*Setting the width of column 1.*/

#title {
  text-align: center;
  font-family: arial, sans-serif;
}

#rpmEligibleData {
  text-align: center;
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  /* border-collapse: collapse; */
  /* border: 3px solid #ddd; */
  width: 100%;
  table-layout: fixed;
}

.table-data {
  font-size: '8px';
}

#rpmEligibleData td,
#rpmEligibleData th {
  /* border: 1px solid #ddd; */
  padding: 8px;
}

#rpmEligibleData tr:nth-child(even) {
  background-color: #ffffff;
}

#rpmEligibleData tr:hover {
  background-color: #ddd;
}

#rpmEligibleData th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #90caf9;
  color: black;
}

.css-13xfq8m-MuiTabPanel-root {
  /* padding: 10px !important; */
  padding-top: 20px !important;
  padding: none !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 10px !important;
}

#printProviderName {
  visibility: hidden;
}

@media print {
  #printProviderName {
    visibility: visible;
  }
}
