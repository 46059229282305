.billingLogs {
  text-align: center;
  margin-top: 10px;
}
.billingLogsCenterLoading {
  height: calc(100vh - 200px);
  display: grid;
  place-items: center;
}
#patientSympData td:nth-of-type(1),
#patientSympData th:nth-of-type(1) {
  width: auto;
}

#patientSympData td:nth-of-type(10),
#patientSympData th:nth-of-type(10) {
  width: 20%;
}

#patientSympData td:nth-of-type(5),
#patientSympData th:nth-of-type(5) {
  width: 10%;
}
