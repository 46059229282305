.patientName {
  font-size: 1em;
  font-weight: bold;
  padding-bottom: 15px;
}
.centerLoading {
  display: flex;
  justify-content: center;
}
.messagesLogContainer {
  padding: 40px;
}
.errorText {
  text-align: center;
  margin-top: 20%;
  color: red;
  opacity: 0.8;
  font-size: 20px;
}
.tableContainer {
  width: 100%;
  margin-top: 30px;
  border: 1px solid black;
  text-align: left;
  border-collapse: collapse;
  font-size: 14px;
}
tr {
  border: 1px solid black;
}
td {
  border: 1px solid black;
}

.no-border {
  border: none;
}

.halfGrid {
  display: grid;
  grid-template-columns: 50% 50%;
  border-collapse: collapse;
  padding-left: 5px;
}
.padding {
  padding-left: 5px;
}
.chatTableItem {
  border: 1px solid black;
}
.indent {
  margin-left: 30px;
  padding-left: 5px;
}
.centerText {
  text-align: center;
}

.loadingContainer {
  width: 100%;
  height: 80px;
  display: grid;
  place-items: center;
}

.textCenterAlign {
  text-align: center;
}

.leftMargin {
  margin-left: 10px;
}

.messageContentTableCell {
  max-width: 40vw;
  word-wrap: break-word;
}

.moreMessagesLoadingContainer {
  display: grid;
  place-items: center;
}

.noDataContainer {
  text-align: center;
  margin-top: 10px;
  height: 80px;
}

.messageLogCollectionContainer:not(:has(.noDataContainer))
  > .messagesLogContainer:not(:has(.tableContainer))::after {
  display: block;
  content: 'No Data for this Patient';
  text-align: center;
  margin-top: 10px;
}
