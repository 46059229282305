.networkInfoContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.networkInfoTitleText {
  color: #106a9c;
  font-size: 14px;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.networkUserContainer {
  display: flex;
}
.networkUserAlertIcon {
  margin: auto;
}
.networkUserNameTextContainer {
  margin-left: 20px;
  align-items: center;
}
.networkUserNameText {
  color: #106a9c;
  font-size: 16px;
}
.networkUserSpecialityText {
  font-size: 14px;
}
.centerLoading {
  display: flex;
  justify-content: center;
}
.marginTop {
  margin-top: 20px;
}
.marginLeft {
  margin-left: 45px;
}
.noMembers {
  color: #106a9c;
  margin-top: 10px;
  margin-bottom: 10px;
}
.optionName {
  width: 50%;
}
.optionRole {
  width: 50%;
  text-align: right;
}
