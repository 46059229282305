#patientSympData td {
  overflow: hidden;
} /*Hide text outside the cell.*/
#patientSympData td:nth-of-type(1),
#patientSympData th:nth-of-type(1) {
  width: 30%;
} /*Setting the width of column 1.*/

#title {
  text-align: center;
  font-family: arial, sans-serif;
}

#patientSympData {
  text-align: center;
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;
  table-layout: fixed;
}

#patientSympData td,
#patientSympData th {
  border: 1px solid #ddd;
  padding: 8px;
}

#patientSympData tr:nth-child(even) {
  background-color: #f2f2f2;
}

#patientSympData tr:hover {
  background-color: #ddd;
}

#patientSympData th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #efefef;
  color: black;
}

.infoCard {
  width: fit-content;
  margin: 0 auto;
}
.rowContainer {
  background-color: '#90caf9';
}

.callLogsCenterLoading {
  height: calc(100vh - 200px);
  display: grid;
  place-items: center;
}

.textCenter {
  text-align: center;
}
