.userListContainer {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.listItemDisplay {
  display: inline-block;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100vh;
  width: 55%;
}

.searchBar {
  width: 25%;
  margin-top: 10px;
  margin-bottom: 10px;
  align-self: center;
  /* margin-left: 15%; */
}

.searchBtn {
  width: 25%;
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}

.noDataContainer {
  height: calc(100vh - 300px);
  display: grid;
  place-items: center;
}

.patientsListing {
  width: 100%;
  max-width: 980px;
  margin: auto;
  /* padding-top: 15px; */
  margin-top: 15px;
}

tr {
  border: 1px solid transparent !important;
}

td {
  border: 1px solid transparent !important;
}

.css-1ex1afd-MuiTableCell-root {
  border-bottom: 1px solid transparent !important;
}

.css-34nofg-MuiTableRow-root {
  transition: all 0.5s ease-in-out;
}

.css-34nofg-MuiTableRow-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.css-1ex1afd-MuiTableCell-root {
  display: flex !important;
  align-items: center !important;
  gap: 10px;
}

.filter-grp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  max-width: 980px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 980px) {
  .filter-grp {
    padding-left: 40px;
    padding-right: 40px;
  }

  .searchButton {
    align-self: flex-start;
    margin-top: 20px;
  }
}

.mr-10 {
  margin-right: 10px;
}

.inp-ht {
  height: 0.4rem;
}

#outlined-basic-label {
  top: -7px;
}

.table-row :hover {
  background-color: lightgray;
}
