table.change_log {
  border: 1px solid black;
  width: 100%;
  margin-bottom: 50px;
}

table.change_log tbody tr th div {
  display: inline-block;
  text-align: left;
}

table.change_log tbody tr th div.attribute {
  background-color: #90caf9;
  width: 20%;
}

table.change_log tbody tr th div.value {
  background-color: #90caf9;
  width: 40%;
}

table.change_log tbody tr td div.bold {
  font-weight: bold;
}

table.change_log tbody tr td div {
  display: inline-block;
}

table.change_log tbody tr td div.attribute {
  font-style: italic;
  width: 20%;
}

table.change_log tbody tr td div.complex_attribute {
  font-style: italic;
  padding-left: 5%;
  width: 20%;
}

table.change_log tbody tr td div.value {
  width: 40%;
}

table.change_log tbody tr:nth-child(odd) {
  background: #ccccf0;
}

table.change_log thead tr td {
  border: 1px solid black;
}

.changesLogListCenterLoading {
  display: grid;
  place-items: center;
  height: calc(100vh - 200px);
}
